<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <!-- Opções Kanban -->
      <div class="container p-0">

        <div class="d-flex justify-content-between flex-wrap" v-if="!fastKanbanBoards.length">
          <a class="btn-novo btn-light my-1" :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')">
            <b-icon-arrow-return-left /> Voltar
          </a>
          <a class="btn-novo btn-warning my-1" @click="exibeModalCriarQuadro()"><small>Criar quadro kanban</small></a>
        </div>

        <div class="d-flex justify-content-between flex-wrap" v-else>
          <a class="btn-novo btn-light my-1" :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')">
            <b-icon-arrow-return-left /> Voltar
          </a>
          <a v-if="!fastKanbanSelected.column.length || fastKanbanSelected.column.length && !fastKanbanSelected.column[0].idColumn" class="btn-novo btn-info text-white my-1 px-3" disabled><small>Adicionar entrada</small></a>
          <a v-else class="btn-novo btn-info text-white my-1 px-3" @click="exibeModalAdicionarEntrada()"><small>Adicionar entrada</small></a>

          <!-- <b-dropdown variant="transparent text-white" class="btn-novo btn-primary text-white my-1" toggle-class="text-decoration-none" no-caret>
            <template #button-content>Gerenciar Kanban</template>
            <b-dropdown-item variant="success" @click="exibeModalCriarQuadro()"><small>Criar quadro kanban</small></b-dropdown-item>
            <b-dropdown-item variant="primary" @click="exibeModalGerenciarQuadro()"><small>Gerenciar quadro</small></b-dropdown-item>
          </b-dropdown> -->

          <a class="btn-novo btn-warning my-1" @click="exibeModalCriarQuadro()"><small>Criar quadro kanban</small></a>
          <a class="btn-novo btn-primary text-white my-1" @click="exibeModalGerenciarQuadro()"><small>Gerenciar quadro</small></a>
          <div>
            <select class="form-control form-control-sm my-1" v-model="fastKanbanSelected">
              <option v-for="k in fastKanbanBoards" :key="k.idKanban" :value="k">{{ k.nameKanban }}</option>
            </select>
          </div>

        </div>
      </div>
      <!-- /Opções Kanban -->

      <!--Kanban -->
      <div class="my-2">
          <div v-if="!fastKanbanSelected.column.length || fastKanbanSelected.column.length && !fastKanbanSelected.column[0].idColumn" class="text-center">
            Não há colunas para serem exibidas.
          </div>
          <div v-else class="row mx-2 kanban overflow-x-auto">
            <div class="d-flex">
              <div v-for="e in fastKanbanSelected.column" :key="e.idColumn" v-bind:class="e.color" class="mx-1 d-inline-block overflow-y-auto shadow rounded">
                <div class="card mx-1 p-1 max-height-kanban bg-card-kanban border-0">
                  <h6>{{ e.nameColumn }}</h6>
                  <draggable class="list-group" group="element">
                    <transition-group>
                      <div class="list-group-item mb-2 d-flex flex-column p-1" v-for="x in e.elements" :key="x.idElement">
                        <span class="mr-2 inline-block"><h6 class="kanban-title">{{ x.nameElement }}</h6></span>
                        <span class="kanban-text">Dados teste</span>
                        <b-dropdown size="sm" variant="light rounded" class="ml-auto">
                          <b-dropdown-item @click="exibeModalEditarEntrada()"><small>Editar</small></b-dropdown-item>
                          <b-dropdown-item @click="exibeModalExcluirEntrada()"><small>Remover</small></b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <modal
      name="modarCriarQuadro"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-10 col-md-10 col-lg-10 pl-4 pr-4">
          <h4>Adicionar novo quadro Kanban</h4>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn-novo btn-secondary"
            href="#"
            @click.prevent="hideModal('modarCriarQuadro')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4 text-nowrap">
          <label>
            Nome do novo quadro:
            <input type="text" class="form-control">
          </label>
          <a class="btn-novo btn-success text-white m-3"><small>Criar novo quadro</small></a>
        </div>
      </div>
    </modal>
    <modal
      name="modalAdicionarEntrada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-10 col-md-10 col-lg-10 pl-4 pr-4">
          <h4>Adicionar entrada</h4>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn-novo btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAdicionarEntrada')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4 text-nowrap">
          <label>
            Coluna
            <select name="colunas" class="form-control">
              <option v-for="e in fastKanbanSelected.column" :key="e.idColumn">{{ e.nameColumn }}</option>
            </select>
          </label>
          <label>
            Nome da nova entrada:
            <input type="text" class="form-control">
          </label>
            <a class="btn-novo btn-success text-white m-3"><small>Adicionar entrada</small></a>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarEntrada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-10 col-md-10 col-lg-10 pl-4 pr-4">
          <h4>Editar entrada</h4>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn-novo btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEntrada')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4 text-nowrap">
          <div class="row d-flex justify-content-center align-items-center">
            <label>
              Nome:
              <input type="text" class="form-control">
            </label>
            <a class="btn-novo btn-success text-white m-3"><small>Salvar</small></a>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirEntrada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-10 col-md-10 col-lg-10 pl-4 pr-4">
          <h4>Adicionar entrada</h4>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn-novo btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirEntrada')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4 text-nowrap">
          <div class="row d-flex justify-content-center align-items-center">
            Deseja realmente excluir <strong>[nome]</strong>?
            <a class="btn-novo btn-danger text-white m-3"><small>excluir entrada</small></a>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalGerenciarQuadro"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-6 col-md-6 col-lg-6 pl-4 pr-4">
          <h4>Gerenciar Quadro</h4>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn-novo btn-secondary"
            href="#"
            @click.prevent="hideModal('modalGerenciarQuadro')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row d-flex justify-content-between">
              <input class="form-control w-50 align-items-center" type="text" v-model="fastKanbanSelected.nameKanban">
              <div class="row d-flex justify-content-between align-items-center mx-1">
                <label for="principal">
                  Principal:
                </label>
                <input id="principal" name="principal" class="mb-1 mx-2 checkbox-size form-control form-control-sm" type="checkbox">
              </div>
              <a class="btn-novo btn-outline-danger">
                <small class="btn-novo-outline-danger">Excluir quadro</small>
              </a>
            </div>
          </div>
        </div>
          <div class="my-2 mx-auto">
            <div class="table-responsive">
              <table class="table table-sm table-striped">
                <tbody>
                  <draggable v-model="fastKanbanSelected.column" group="column" @change="log" >
                      <tr v-for="e in fastKanbanSelected.column" :key="e.idColumn">
                        <td>
                          <img src="../../../assets/images/app/3 barras.png" class="img-mover-coluna pt-1 mx-2">
                        </td>
                        <td>
                          <input type="text" class="form-control" v-model="e.nameColumn">
                        </td>
                        <td class="text-center">
                          <select class="form-control" v-model="e.color">
                            <option value="bg-amarelo">Amarelo</option>
                            <option value="bg-azul">Azul</option>
                            <option value="bg-branco">Branco</option>
                            <option value="bg-laranja">Laranja</option>
                            <option value="bg-verde">Verde</option>
                          </select>
                        </td>
                        <td class="text-center pt-2">
                          <a
                            class="btn-novo btn-sm btn-danger py-0 mr-2"
                          >
                            <small
                              class="text-white"
                            >Excluir</small>
                          </a>
                        </td>
                      </tr>
                  </draggable>
                </tbody>
              </table>
            </div>
          </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row px-4">
            <a class="btn-novo btn-info text-white"><small>Adicionar coluna</small></a>
            <a class="btn-novo btn-success text-white ml-auto"><small>Salvar alterações</small></a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import draggable from "vuedraggable"; //https://github.com/SortableJS/Vue.Draggable
import rawDisplayer from "../../../helper/raw-displayer.vue";

export default {
  name: "HomeInternoSecretariaKanban",
  components: {draggable, rawDisplayer},

  mixins: [methods],
  data: function () {
    return {
      modalWidth: "80%",
      modalWidthSm: this.isMobile() ? "60%" : "40%",
      modalWidthMd: this.isMobile() ? "90%" : "60%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastKanbanBoards: [
        {
          nameKanban: "Kanban Secretaria",
          idKanban: 1001,
          defaultKanban: true,
          column: [
            { nameColumn: "suporte", 
              idColumn: 1,
              color: "bg-azul",
              owner: "", 
              elements: [
                { nameElement: "Sabrina Santos", idElement: 101 },
                { nameElement: "Silvio Santos", idElement: 102 },
                { nameElement: "Sandra Santos", idElement: 103 },
                { nameElement: "Sorondo Santos", idElement: 104 }
              ]
            },
            { nameColumn: "acolhimento", 
              idColumn: 2,
              color: "bg-amarelo",
              owner: "",  
              elements: [
                { nameElement: "Alberto Almeida", idElement: 105 },
                { nameElement: "Alexa Almeida", idElement: 106 },
                { nameElement: "Alejandro Almeida", idElement: 107 },
                { nameElement: "Alisson Almeida", idElement: 108 }
              ]
            },
            { nameColumn: "secretaria", 
              idColumn: 3,
              color: "bg-verde",
              owner: "",  
              elements: [
                { nameElement: "John Javascript", idElement: 109 },
                { nameElement: "Joao Javascript", idElement: 110 },
                { nameElement: "Jean Javascript", idElement: 111 },
                { nameElement: "Juan Javascript", idElement: 112 }
              ]
            },
            { nameColumn: "certificacao", 
              idColumn: 4,
              color: "bg-laranja",
              owner: "",  
              elements: [
                { nameElement: "Charles Correia", idElement: 113 },
                { nameElement: "Cesar Correia", idElement: 114 },
                { nameElement: "Claudete Correia", idElement: 115 },
                { nameElement: "Cintia Correia", idElement: 116 }
              ]
            },
            { nameColumn: "etapa teste 5", 
              idColumn: 5,
              color: "bg-branco",
              owner: "",  
              elements: [
                { nameElement: "Charles Correia", idElement: 117 },
                { nameElement: "Cesar Correia", idElement: 118 },
                { nameElement: "Claudete Correia", idElement: 119 },
                { nameElement: "Cintia Correia", idElement: 120 }
              ]
            },
            { nameColumn: "etapa teste 6", 
              idColumn: 6,
              color: "bg-branco",
              owner: "",  
              elements: [
                { nameElement: "Charles Correia", idElement: 121 },
                { nameElement: "Cesar Correia", idElement: 122 },
                { nameElement: "Claudete Correia", idElement: 123 },
                { nameElement: "Cintia Correia", idElement: 124 }
              ]
            },
            { nameColumn: "etapa teste 7", 
              idColumn: 7,
              color: "bg-branco",
              owner: "",  
              elements: [
                { nameElement: "Charles Correia", idElement: 125 },
                { nameElement: "Cesar Correia", idElement: 126 },
                { nameElement: "Claudete Correia", idElement: 127 },
                { nameElement: "Cintia Correia", idElement: 128 }
              ]
            },
            { nameColumn: "etapa teste 8", 
              idColumn: 8,
              color: "bg-branco",
              owner: "",  
              elements: [
                { nameElement: "Charles Correia", idElement: 129 },
                { nameElement: "Cesar Correia", idElement: 130 },
                { nameElement: "Claudete Correia", idElement: 131 },
                { nameElement: "Cintia Correia", idElement: 132 }
              ]
            },
          ],
        }, 
        {
          nameKanban: "Kanban Vazio",
          idKanban: 1002,
          defaultKanban: false,
          column: [],
        }, 
      ],
      fastKanbanSelected: {
          nameKanban: "",
          idKanban: "",
          defaultKanban: "",
          column: [
            { nameColumn: "", 
              idColumn: "",
              color: "",
              owner: "", 
              elements: [
                { nameElement: "", idElement: "" },
              ]
            },
          ],
        }, 
      fastKanbanModify: {
        nameKanban: "",
        idKanban: "",
        defaultKanban: "",
        column: [
          { nameColumn: "", 
            idColumn: "",
            color: "",
            owner: "", 
            elements: [
              { nameElement: "", idElement: "" },
            ]
          },
        ],
      }, 
    };
  },
  mounted: function () {
    this.fastKanbanBoards.forEach(e => {
      if (e.defaultKanban){
        this.fastKanbanSelected = e
        e.nameKanban += " (Principal)"
      }
    });
  },
  methods: {
    log: function(evt) {
      window.console.log(evt);
    },
    exibeModalCriarQuadro(){
      this.showModal('modarCriarQuadro')
    },
    exibeModalGerenciarQuadro(){
      this.showModal('modalGerenciarQuadro')
    },
    exibeModalAdicionarEntrada(){
      this.showModal('modalAdicionarEntrada')
    },
    exibeModalEditarEntrada(){
      this.showModal('modalEditarEntrada')
    },
    exibeModalExcluirEntrada(){
      this.showModal('modalExcluirEntrada')
    },
    criaQuadro(){
    },
    excluiQuadro(){
    },
    adicionarEntrada(){
    },
    editarEntrada(){
    },
    excluirEntrada(){
    },
    adicionarColuna(){
    },
    editarColuna(){
    },
    excluirColuna(){
    },
  },
};
</script>

<style scope>
.img-mover-coluna {
  height: 1rem;
  width: 1rem;
  cursor: grab;
}
.drag-align {
  display: flex;
}
.checkbox-size {
  height: 15px;
  width: 15px;
}
.kanban {
  height: 85vh;
}
.kanban-title{
  font-size: small;
}
.kanban-text{
  font-size: x-small;
}
.text-btn-kanban{
  border: none;
}
.bg-card-kanban {
  background: transparent;
}
.bg-branco{
  background-color: white;
}
.bg-azul {
  background-color: lightblue;
}
.bg-amarelo {
  background-color: lightgoldenrodyellow;
}
.bg-laranja {
  background-color: lightsalmon;
}
.bg-verde {
  background-color: lightgreen;
}
.max-height-kanban{
  height: 31rem;
  max-height: 31rem;
  min-width: 13rem;
}
.overflow-y-auto {
  overflow-y: auto; 
  overflow-x: hidden;
}
.overflow-x-auto {
  overflow-y: hidden; 
  overflow-x: auto;
}

.btn-novo-outline-danger {
  color: red;
}
.btn-novo-outline-danger:hover {
  color: white;
}
.btn-outline-danger:hover{
  color: white;
}

::-webkit-scrollbar-thumb { border-radius: 10px; background: rgba(0, 0, 0, 0.3);}

</style>
